import Image from 'next/legacy/image';
import PropTypes from 'prop-types';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { getImageUrlWithSizing } from '@/utility/getImageUrlWithSizing';
import { useState } from 'react';
import config from '@/utility/config';
import ProgressiveImage, {
  checkIfNewImageOptimization
} from '../ProgressiveImage';
import classNames from 'classnames';
import { hasBase64Content } from '@/utility/member/helper';

export const generateNextImagePath = ({
  src,
  quality,
  ...extraAttributes
}) => {
  const queryParams = {
    q: quality,
    url: src,
    ...extraAttributes // extra attributes like w, h, etc
  };

  return `/_next/image?${new URLSearchParams(queryParams).toString()}`;
};

export const checkPropsValidity = ({
  mobileImgProps,
  tabletImgProps,
  desktopImgProps
}) => {
  const hasSrc =
    mobileImgProps?.src || tabletImgProps?.src || desktopImgProps?.src;

  return !!hasSrc;
};

/*
4 cases
- both mobile and desktop src and props are different
- both mobile and desktop src and props are same, just pass mobile props
- both mobile and desktop src are same, props different
- both mobile and desktop src are different, props same
*/

const NextImage = (props) => {
  const {
    alt,
    mobileImgProps,
    tabletImgProps,
    desktopImgProps,
    style,
    className,
    isDynamic,
    isNative,
    isOptimized = true
  } = props;
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { isDesktopView, isTabletView } = useWindowWidthContext();

  const isValidProps = checkPropsValidity({
    mobileImgProps,
    tabletImgProps,
    desktopImgProps
  });

  const returnImageProps = () => {
    const m = mobileImgProps || {};
    const t = tabletImgProps || {};
    const d = desktopImgProps || {};

    // order of precedence: desktop → tablet → mobile (reverse destructure order)
    if (isDesktopView) return { ...m, ...t, ...d };
    else if (isTabletView) return { ...m, ...t };
    else return m;
  };

  const imageProps = returnImageProps();

  const handleImageError = () => {
    setErrorOccurred(true);
  };

  if (!mobileImgProps) {
    // Dont want to throw error here since it fills our Console + Sentry with these errors.
    // Often caused on first paint when props are still being fetched from BE
    // Another issue is this error provides no details on where error is happening
    // so it offers little insight on how to fix it making it pretty much worthless as a debugging aid

    return null;
  }

  let loader;
  if (isDynamic) {
    loader = (imageProps) => {
      return imageProps.src;
    };
  }

  if (!isValidProps) {
    // Dont want to throw error here since it fills our Console + Sentry with these errors.
    // Often caused on first paint when props are still being fetched from BE
    // Another issue is this error provides no details on where error is happening
    // so it offers little insight on how to fix it making it pretty much worthless as a debugging aid

    // console.error('Invalid Props passed to NextImage ', props);
    return null;
  }

  // Prevent base64 image from rendering
  if (hasBase64Content(imageProps.src)) {
    console.error('Base64 image detected: ', imageProps.src);
    return <div></div>;
  }
  //non-optimized images to save cost
  if (
    [
      '(.+)/socialProfilePics/(.+)',
      '(.+)/randomProfileImage',
      '(.+)sample_person(.+)'
    ].some((pattern) => new RegExp(pattern).test(imageProps.src)) ||
    !isOptimized
  ) {
    return (
      <img
        alt={alt}
        className={classNames('non-optimized-image', className)}
        style={{
          ...style,
          width: imageProps.width ? imageProps.width + 'px' : '100%',
          height: imageProps.height ? imageProps.height + 'px' : '100%',
          objectFit: imageProps.objectFit ?? 'cover'
        }}
        width={imageProps.width}
        height={imageProps.height}
        src={imageProps.src}
        onError={handleImageError}
      />
    );
  }

  if (
    config.isImageOptimizationEnabled &&
    checkIfNewImageOptimization(imageProps.src)
  ) {
    return (
      <ProgressiveImage
        alt={alt}
        className={className}
        style={{ ...style }}
        {...imageProps}
      />
    );
  }

  // For SEO, if isNative, we will use normal img, so that it can be crawled by google
  if (
    config.enablePublicPageImageBannerForCache &&
    isNative &&
    !errorOccurred
  ) {
    const resizedImage = getImageUrlWithSizing(
      imageProps.src,
      imageProps.width,
      imageProps.height
    );
    return (
      <img
        alt={alt}
        className={className}
        style={{ ...style }}
        src={resizedImage}
        onError={handleImageError}
      />
    );
  }

  return (
    <div className={className}>
      <Image
        alt={alt}
        className={className}
        blurDataURL={generateNextImagePath({
          src: imageProps.src,
          quality: 1,
          w: 16
        })}
        placeholder="blur"
        {...imageProps}
        style={{ ...style }}
        loader={loader}
      />
    </div>
  );
};

export const NextImagePropTypes = {
  alt: PropTypes.string,
  mobileImgProps: PropTypes.object,
  tabletImgProps: PropTypes.object,
  desktopImgProps: PropTypes.object,
  isDynamic: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string
};

NextImage.propTypes = NextImagePropTypes;

export default NextImage;
