import { usePathname } from 'next/navigation';
import React from 'react';

import config from '@/utility/config';
import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { t } from '@/utility/localization';

import Head from '@/components/common/Head';

import { generateNextImagePath } from '../NextImage/NextImage';
import {
  checkIfNewImageOptimization,
  generateImagePath
} from '../ProgressiveImage';

const PageMetaTags = ({
  title = t('learn-directly-from-excellent'),
  description = t('our-classes-are-highly-engagin'),
  url = '',
  canonicalUrl = '',
  imageUrl = `${STATIC_ASSET_BASE_URL}/ssr-academy/metatagImages/jpg/defaultHomeWithLogo.jpg`,
  children = null,
  faviconUrl = '',
  thumbnailUrl = '',
  indexable = true
}) => {
  const pathname = usePathname();
  const updatedCanonicalUrl =
    canonicalUrl || url || config.siteBaseUrl + pathname;

  const imagePath =
    config.isImageOptimizationEnabled &&
    checkIfNewImageOptimization(imageUrl)
      ? generateImagePath({
          src: imageUrl,
          quality: 100
        })
      : config.siteBaseUrl +
        generateNextImagePath({
          src: imageUrl,
          quality: 25,
          w: 640
        });

  return (
    <Head>
      <meta property="twitter:card" content="summary_large_image" />
      {/* Title */}
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />

      {/* Image */}
      <meta name="image" property="og:image" content={imagePath} />
      <meta property="twitter:image" content={imagePath} />
      {/* FavIcon */}
      <link rel="icon" href={faviconUrl} />

      {thumbnailUrl && <meta name="thumbnail" content={thumbnailUrl} />}

      {/* Description */}
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />

      {/* Url */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="twitter:url" content={url} />
      <link rel="canonical" href={updatedCanonicalUrl} />

      {indexable ? (
        <meta
          name="robots"
          content="index,follow,max-snippet:-1,max-image-preview:large,max-video-preview:-1"
        />
      ) : (
        <meta name="robots" content="noindex,nofollow" />
      )}

      {/* Others */}
      {children}
    </Head>
  );
};

export default PageMetaTags;
