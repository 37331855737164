import classNames from 'classnames';
import { CSSProperties, FC, useEffect, useState } from 'react';
import Head from '../Head';

export const enum ImageFileExtension {
  WEBP = 'webp',
  JPG = 'jpg',
  PNG = 'png'
}

//we only support 4 variants: 1, 25, 50, 100
//if it's not in this list, it will default to 100
const qualityList = [1, 25, 50, 100] as const;
export type Quality = (typeof qualityList)[number];

type ProgressiveImageProps = {
  src: string;
  alt: string;
  quality?: Quality;
  isThumbnail?: boolean;
  fileExtension?: ImageFileExtension;
  style?: CSSProperties;
  layout: 'fill' | 'responsive';
  className?: string;
  width?: string | number;
  height?: string | number;
  priority?: boolean;
  objectFit?: CSSProperties['objectFit'];
};

export const checkIfNewImageOptimization = (src: string) => {
  if (!src || typeof src !== 'string' || src?.length === 0) {
    return;
  }

  //nio_ is the magic prefix for new image optimization
  return src.includes('/nio_');
};

const checkNumber = (value: number | string) => {
  return typeof value === 'number' || !Number.isNaN(+value);
};

const checkWebPSupport = () => {
  //assume that the webp is supported by default in the server side
  if (typeof window === 'undefined') {
    return true;
  }

  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

export const generateImagePath = ({
  src,
  quality = 100,
  isThumbnail = false
}: {
  src: string;
  quality?: Quality;
  isThumbnail?: boolean;
}) => {
  const fileExtension = checkWebPSupport()
    ? ImageFileExtension.WEBP
    : ImageFileExtension.JPG;

  return isThumbnail
    ? `${src}_thumbnail.${fileExtension}`
    : `${src}_${quality}.${fileExtension}`;
};

const checkCachedImage = (src: string) => {
  if (typeof window === 'undefined') {
    return true;
  }

  const image = new Image();
  image.src = src;

  return image.complete;
};

const ProgressiveImage: FC<ProgressiveImageProps> = ({
  src,
  quality = 100,
  isThumbnail = false,
  alt,
  width,
  height,
  objectFit = 'cover',
  layout,
  style,
  priority,
  className
}) => {
  if (!qualityList.includes(quality)) {
    console.warn('Invalid quality value, defaulting to 100');
    quality = 100;
  }

  const lowQualitySrc = generateImagePath({ src, quality: 1 });
  const highQualitySrc = generateImagePath({ src, quality, isThumbnail });

  const [currentSrc, setCurrentSrc] = useState(
    checkCachedImage(highQualitySrc) ? highQualitySrc : lowQualitySrc
  );
  useEffect(() => {
    if (checkCachedImage(highQualitySrc)) {
      setCurrentSrc(highQualitySrc);
      return;
    }
    setCurrentSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setCurrentSrc(highQualitySrc);
    };
  }, [src]);

  const isBlur = currentSrc === lowQualitySrc;

  return (
    <>
      {priority && (
        <Head>
          <link rel="preload" as="image" href={highQualitySrc} />
        </Head>
      )}
      <div
        className={classNames(className, {
          'relative overflow-hidden': layout !== 'fill'
        })}
        style={
          layout === 'fill'
            ? {}
            : {
                width: checkNumber(width) ? `${width}px` : 'initial',
                height: checkNumber(height) ? `${height}px` : 'initial',
                overflow: 'hidden'
              }
        }>
        <span
          style={{
            boxSizing: 'border-box',
            display: 'block',
            overflow: 'hidden',
            width: checkNumber(width) ? `${width}px` : 'initial',
            height: checkNumber(height) ? `${height}px` : 'initial',
            background: 'none',
            opacity: 1,
            border: 0,
            margin: 0,
            padding: 0,
            position: 'absolute',
            inset: 0
          }}>
          <img
            alt={alt}
            style={{
              ...style,
              filter: isBlur ? 'blur(20px)' : 'none',
              transition: isBlur ? 'none' : 'filter 0.3s ease-out',
              width: 0,
              height: 0,
              objectFit: objectFit,
              position: 'absolute',
              padding: 0,
              margin: 'auto',
              minWidth: '100%',
              minHeight: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              inset: 0,
              boxSizing: 'border-box',
              display: 'block'
            }}
            className={className}
            src={currentSrc}
          />
        </span>
      </div>
    </>
  );
};

export default ProgressiveImage;
